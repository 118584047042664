import { axiosMultiPart } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { setupAuthorizationInterceptor } from "../interceptors/authorization.interceptor";
import { setupCsrfInterceptor } from "../interceptors/csrf.interceptor";
import { setupLoadingInterceptor } from "../interceptors/loading.interceptor";
import { setupNotificationInterceptor } from "../interceptors/notification.interceptor";
import { useLoading } from '../context/LoadingProvider';
import { useNotifications } from '../context/NotificationProvider';
import { setupDecryptorInterceptor } from "../interceptors/encryptor.interceptor";

const useAxiosMultiPart = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const loader = useLoading();
    const notify = useNotifications();

    useEffect(() => {
        const authorizationInterceptor = setupAuthorizationInterceptor(axiosMultiPart, refresh, auth);
        const decryptorInterceptor = setupDecryptorInterceptor(axiosMultiPart);
        const csrfInterceptor = setupCsrfInterceptor(axiosMultiPart);
        const loadingInterceptor = setupLoadingInterceptor(axiosMultiPart, loader);
        const notificationInterceptor = setupNotificationInterceptor(axiosMultiPart, notify);
        return () => {
            // Cleanup interceptors
            authorizationInterceptor();
            csrfInterceptor();
            decryptorInterceptor();
            loadingInterceptor();
            notificationInterceptor();
        };
    }, [auth, refresh, loader, notify]);

    return axiosMultiPart;
};

export default useAxiosMultiPart;
