import { axiosDefault } from "../api/axios";
import { useEffect } from "react";
import { setupEncryptorDecryptorInterceptor } from "../interceptors/encryptor.interceptor";
import { setupCsrfInterceptor } from "../interceptors/csrf.interceptor";
import { setupLoadingInterceptor } from "../interceptors/loading.interceptor";
import { setupNotificationInterceptor } from "../interceptors/notification.interceptor";
import { useLoading } from '../context/LoadingProvider';
import { useNotifications } from '../context/NotificationProvider';

const useAxiosDefault = () => {
    const loader = useLoading();
    const notify = useNotifications();

    useEffect(() => {
        const encryptorDecryptorInterceptor = setupEncryptorDecryptorInterceptor(axiosDefault);
        const csrfInterceptor = setupCsrfInterceptor(axiosDefault);
        const loadingInterceptor = setupLoadingInterceptor(axiosDefault, loader);
        const notificationInterceptor = setupNotificationInterceptor(axiosDefault, notify);
        return () => {
            encryptorDecryptorInterceptor();
            csrfInterceptor();
            loadingInterceptor();
            notificationInterceptor();
        };
    }, [loader, notify]);

    return axiosDefault;
};
export default useAxiosDefault;
