import AuthService from '../services/auth.service';
import { endpoints } from '../utils/utils';
import useAuth from './useAuth';
import useAxiosDefault from './useAxiosDefault';
import useLogout from './useLogout';

const useRefreshToken = () => {
    // eslint-disable-next-line no-unused-vars
    const { auth, setAuth } = useAuth();
    const logout = useLogout();
    const axiosDefault = useAxiosDefault();
    const refresh = async () => {
        let response;
        if (auth?.accessToken) {
            try {
                response = await axiosDefault.get(endpoints.refreshToken, {
                    headers: {
                        'Authorization': `Bearer ${auth?.accessToken}`
                    }
                });
                if (response.data?.token) {
                    setAuth(prev => {
                        return {
                            ...prev,
                            user: response.data?.user,
                            roles: response.data?.user.roles,
                            accessToken: response?.data.token
                        }
                    });
                    localStorage.setItem("accessToken", response.data?.token);
                    return response.data?.token;
                } else {
                    await logout();
                    return null;
                }

            } catch (error) {
                await logout();
            }

        } else {
            await logout();
        }
    }
    return refresh;
};

export default useRefreshToken;
