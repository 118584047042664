import "./Breadcrumb.css";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { CiClock2, CiCalendar } from "react-icons/ci";
// import useAxiosPrivate2 from "../../hooks/useAxiosPrivate2";
import BookingService from "../../services/booking.service";
import ProductService from "../../services/product.service";
import CategoryService from "../../services/category.service";
import UserService from "../../services/user.service";
import Select from 'react-select';
import cantons from "../../shared/data/cantons.json";
import { useNotifications } from '../../context/NotificationProvider';
import { formatTimeTo24Hour } from "../../shared/utils/string";
import { Map } from "../google_map";
import axios from "../../api/axios";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    height: '680px',
  },
};

const timerStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    maxWidth: '350px',
    padding: '0px 0px 0px 0px',
  },
};

const customSelectStyles = {
  control: (styles) => ({
    ...styles,
    width: '100%',
    height: '60px',
    padding: '0 15px',
    fontSize: '14px',
    lineHeight: '60px',
    color: '#393D44',
    border: '0',
    borderRadius: '20px',
    background: '#ffffff',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  // placeholder: (base) => ({
  //   ...base,
  //   marginTop: '-10px',
  // }),
  // dropdownIndicator: (base) => ({
  //   ...base,
  //   marginTop: '-10px',
  // }),
};



const Breadcrumb = ({ onViewChange, selected, axiosPrivate }) => {
  Modal.setAppElement('#root');
  // const axiosPrivate = useAxiosPrivate2();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);

  const [value, setValue] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [bookings, setBookings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const [flypros, setFlypros] = useState([]);
  const [customers, setCustomers] = useState([]);
  const notify = useNotifications();

  const [isAddressUpdate, setIsAddressUpdate] = useState(false);
  const [coordinates, setCoordinate] = useState({
    lat: 46.356,
    lng: 6.5641
  });

  const [formData, setFormData] = useState({
    category_id: "",
    subcategory_id: "",
    product_id: "",
    parent_category_id: "",
    duration: "",
    date: "",
    date_only: "",
    timeBooking: "",
    description: "",
    message: "",
    postcode: "",
    therapist_id: [],
    client_id: "",
    address_id: "",
    // promoCode: "",
    status: "CREATED",
    zip_code: "",
    state: "",
    state_code: "",
    street: "",
    street_number: "",
    city: "",
    country: "Switzerland",
    country_code: "CH",
    latitude: null,
    longitude: null
  });

  const handleButtonClick = (view) => {
    onViewChange(view);
  };

  const resetForm = () => {
    setFormData({
      category_id: "",
      subcategory_id: "",
      product_id: "",
      duration: "",
      parent_category_id: "",
      date: "",
      date_only: "",
      timeBooking: "",
      message: "",
      address_id: "",
      description: "",
      postcode: "",
      therapist_id: [],
      client_id: "",
      // promoCode: "",
      status: "CREATED",
      zip_code: "",
      state: "",
      state_code: "",
      street: "",
      street_number: "",
      city: "",
      country: "Switzerland",
      country_code: "CH",
      latitude: null,
      longitude: null
    });
    setSelectedFlyproOption([]);
    setSubCategories([]);
    setProductList([]);
    setCoordinate({
      lat: 46.356,
      lng: 6.5641
    })
    setIsAddressUpdate(false);
  };

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
  }

  const handleOkClick = () => {
    setValue(selectedTime);
    const timeString = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    setFormData({
      ...formData,
      timeBooking: timeString,
    });
    setTimePickerIsOpen(false);
  };

  const handleCancelClick = () => {
    setTimePickerIsOpen(false);
  };

  const openDatePicker = () => {
    setDatePickerIsOpen(true);
  };

  const closeDatePicker = () => {
    setDatePickerIsOpen(false);
  };

  const handleDatePickerOk = () => {
    const dateString = value.toLocaleDateString('en-CA');
    setFormData({
      ...formData,
      date_only: dateString,
    });
    closeDatePicker();
  };

  // const fetchBookings = async () => {
  //   try {
  //     const response = await BookingService.index({ with: 'address,client,product,creator,therapist' }, axiosPrivate);
  //     setBookings(response);
  //   } catch (error) {

  //   }
  // };

  // useEffect(() => {
  //   fetchBookings();
  // }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    let state = cantons.cantons.find(
      (canton) => canton.name === formData.state
    );

    const [start, end] = state?.zip_code_range?.split("-")?.map(Number) || [0, 0];
    if (formData.state != null && (formData.zip_code < start || formData.zip_code > end)) {
      notify.warn(`Zip Code should be in the valid range of ${state.name}: [${state.zip_code_range}]`);
      setFormData({
        ...formData,
        zip_code: '',
      });
      return;
    }

    try {
      await fetchCoordinates();
    } catch (error) {

    }

    formData.state_code = state?.abbreviation;
    formData.country = "Switzerland";
    formData.country_code = "CH";
    const time = formatTimeTo24Hour(formData.timeBooking);
    const combinedDateTime = `${formData.date} ${time}`;
    formData.date = combinedDateTime;
    formData.start_before = combinedDateTime;

    try {
      const response = await BookingService.store(formData, axiosPrivate);
      if (response.data) {
        closeModal();
      }
    } catch (error) {

    }
  };
  const fetchAvailableTherapists = async () => {
    try {
      console.log({ date: formData.date, time: formData.timeBooking, latitude: formData.latitude, longitude: formData.longitude })
      const flypro = await UserService.fetchAvailableTherapists({ date: formData.date_only, time: formatTimeTo24Hour(formData.timeBooking), duration: Number(formData.duration), latitude: formData.latitude, longitude: formData.longitude, zip_code: formData.zip_code }, null, axiosPrivate);
      setFlypros(flypro);
    } catch (error) {

    }
  }
  async function openModal() {
    resetForm();
    const categories = await CategoryService.index({ parent_id: null, status: 'ACTIVE' }, axiosPrivate);
    const customer = await UserService.indexByRole({ with: 'primary_address' }, 'ROLE_CLIENT', axiosPrivate);
    setCategories(categories);
    setCustomers(customer);

    setFormData({
      ...formData
    });

    setIsOpen(true);
  };

  const closeModal = () => {
    resetForm();
    setIsOpen(false);
  };

  // Handle category change
  const handleCategorySelectChange = async (selected) => {
    const categoryId = selected ? selected.value : '';
    setFormData(prev => ({ ...prev, category_id: categoryId, subcategory_id: '', product_id: '' }));

    if (categoryId !== '') {
      try {
        const response = await CategoryService.index({ parent_id: categoryId, status: 'ACTIVE' }, axiosPrivate);
        setSubCategories(response);
      } catch (error) {

      }
    } else {
      setSubCategories([]);
    }
  };

  // Handle subcategory change
  const handleSubcategorySelectChange = async (selected) => {
    const subcatId = selected ? selected.value : '';
    setFormData(prev => ({ ...prev, subcategory_id: subcatId, product_id: '' }));

    if (subcatId !== '') {
      try {
        const response = await ProductService.index({ category_id: subcatId }, axiosPrivate);
        setProductList(response);
      } catch (error) {

      }
    } else {
      setProductList([]);
    }
  };

  // Handle product change
  const handleProductSelectChange = (selected) => {
    setFormData(prev => ({
      ...prev,
      product_id: selected ? selected.value : null,
      duration: selected ? selected.duration : ''

    }));
  };

  // Handle flypro change
  const handleFlyproChange = (selected) => {
    console.log({ selected })
    if (selected.length > 2) {
      return;
    }
    if (selected?.length) {
      setSelectedFlyproOption(selected);
      setFormData((prev) => ({
        ...prev,
        therapist_id: selected.map((therapist) => therapist.value)
      }));
    } else {
      setSelectedFlyproOption([]);

      setFormData((prev) => ({
        ...prev,
        therapist_id: []
      }));
    }
  };

  // Handle Customer change
  const handleCustomerChange = (selected) => {
    let client = customers.find((customer) => customer.id === selected?.value);
    if (client && client.primary_address) {
      setFormData((prev) => ({
        ...prev,
        zip_code: client.primary_address?.zip_code,
        state: client.primary_address?.state,
        state_code: client.primary_address?.state_code,
        street: client.primary_address?.street,
        street_number: client.primary_address?.street_number,
        city: client.primary_address?.city,
        latitude: client.primary_address?.latitude,
        longitude: client.primary_address?.longitude,
        address_id: client.primary_address?.id,
        client_id: selected ? selected.value : ''
      }));
      setSelectedState({ value: client.primary_address.state, label: client.primary_address.state })
      setCoordinate({ lat: client.primary_address?.latitude, lng: client.primary_address?.longitude })
    } else {
      setFormData((prev) => ({
        ...prev,
        zip_code: "",
        state: "",
        state_code: "",
        street: "",
        street_number: "",
        city: "",
        latitude: "",
        address_id: null,
        longitude: "",
        client_id: selected ? selected.value : ''
      }));
    }
  };

  // Create options for Category, Subcategory, Product, Flypro
  const categoryOptions = categories?.length > 0 ? categories.map(row => ({ value: row.id, label: row.title })) : [];
  const subcategoryOptions = subCategories?.length > 0 ? subCategories.map(row => ({ value: row.id, label: row.title })) : [];
  const productOptions = productList?.length > 0 ? productList.map(row => ({ value: row.id, label: row.name, duration: row.duration })) : [];
  const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];
  const customerOptions = customers?.length > 0 ? customers.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

  // Determine currently selected options
  const selectedCategoryOption = categoryOptions.find(o => o.value === formData.category_id) || null;
  const selectedSubcategoryOption = subcategoryOptions.find(o => o.value === formData.subcategory_id) || null;
  const selectedProductOption = productOptions.find(o => o.value === formData.product_id) || null;
  // const selectedFlyproOption = flyproOptions.find(option => formData.therapist_id.includes(option.value)) || null;
  const selectedCustomerOption = customerOptions.find(option => option.value === formData.client_id) || null;
  const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
  // Fonction pour mettre à jour les valeurs du formulaire

  const handleChange = async (e) => {

    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  };

  const fetchCoordinates = async () => {
    const { street_number, street, city, state, zip_code } = formData;
    const address = `${street_number} ${street}, ${city}, ${state} ${zip_code}`;

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json`,
        {
          params: {
            address,
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          },
        }
      );

      if (response.data.results.length > 0) {
        const location = response.data.results[0].geometry.location;
        setFormData({
          ...formData,
          latitude: location.lat,
          longitude: location.lng,
        });
        setCoordinate(location)
      } else {
        console.error('No results found for the given address.');
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  };
  const [selectedState, setSelectedState] = useState(null);
  const [showMap, setShowMap] = useState(false);

  const handleShowMap = async () => {
    await fetchCoordinates();
    setShowMap(true);
  }

  const loadTherapist = async () => {
    if (formData.longitude && formData.latitude && formData.duration && formData.date_only && formData.timeBooking && formData.state) {
      await fetchAvailableTherapists();
    } else {
      notify.warn("Missing Data to get available therapists")
    }
  }

  const handleCoordinateChange = (coordinates) => {
    setFormData({
      ...formData,
      longitude: coordinates[0]?.lng,
      latitude: coordinates[0]?.lat,
    })
    setCoordinate({
      lat: coordinates[0]?.lng,
      lng: coordinates[0]?.lat,
    })
  }

  const handleStateChange = (data) => {
    let selected = cantons.cantons.find(
      (canton) => canton.name === data?.value
    );
    document.querySelector('input[name="zip_code"]').setAttribute('placeholder', selected?.zip_code_range || '');
    setFormData({
      ...formData, zip_code: ''
    });
    setFormData({
      ...formData,
      state: data?.value,
    });
    setSelectedState(data);
  };
  return (
    <>
      <div className="bloc-search background-breadcrumb">
        <div className="container">
          <div className="search">
            <div className='breadcrumb-navigation'>
              <button
                className={`breadcrumb-map-button ${selected === 'map' ? 'active' : ''}`}
                onClick={() => handleButtonClick('map')}
              >
                <div className={`icon ${selected === 'map' ? 'active-icon' : ''}`} />
                <span className='breadcrumb-label'>Map</span>
              </button>

              <button
                className={`breadcrumb-list-button ${selected === 'list' ? 'active' : ''}`}
                onClick={() => handleButtonClick('list')}
              >
                <div className={`icon ${selected === 'list' ? 'active-icon' : ''}`} />
                <span className='breadcrumb-label-list'>List</span>
              </button>
            </div>
          </div>
          <button onClick={openModal} className="btn-add-client">
            Create a task
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Add a Booking"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>Create a task</h2>
        <p>If you need more info, please check out</p>
        <div className="form-inscription">
          <form onSubmit={handleFormSubmit}>
            <div className="form-text">
              <label>Category*</label>
              <Select
                value={selectedCategoryOption}
                onChange={handleCategorySelectChange}
                options={categoryOptions}
                styles={customSelectStyles}
                isClearable
                required
                isSearchable
                placeholder="Select a category"
                noOptionsMessage={() => "No categories available"}
              />
            </div>

            <div className="form-text">
              <label>Subcategory*</label>
              <Select
                value={selectedSubcategoryOption}
                onChange={handleSubcategorySelectChange}
                options={subcategoryOptions}
                styles={customSelectStyles}
                required
                isClearable
                isSearchable
                placeholder="Select a Subcategory"
                noOptionsMessage={() => "No Subcategories available"}
                isDisabled={!formData.category_id} // disable if no category selected
              />
            </div>

            <div className="form-text">
              <label>Product*</label>
              <Select
                value={selectedProductOption}
                onChange={handleProductSelectChange}
                options={productOptions}
                styles={customSelectStyles}
                required
                isClearable
                isSearchable
                placeholder="Select a product"
                noOptionsMessage={() => "No products available"}
                isDisabled={!formData.subcategory_id} // disable if no subcategory selected
              />
            </div>

            <div className="form-text">
              <label>Duration</label>
              <input type="number" name="duration" value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} />
            </div>

            <div className="dates">
              <div className="form-text dateOfTheBookingParent topMarg">
                <label>Date of the booking</label>
                <input
                  type="text"
                  name="date"
                  value={formData.date_only}
                  onClick={openDatePicker}
                  readOnly
                />
                <CiCalendar className="date-icon" onClick={openDatePicker} />
              </div>

              <div className="form-text timeOfTheBookingParent topMarg">
                <label>Time of the booking</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={formData.timeBooking}
                  onClick={() => setTimePickerIsOpen(true)}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => setTimePickerIsOpen(true)} />
              </div>
            </div>

            <div className="form-text topMargArea">
              <label>Description</label>
              <textarea className="formTextArea" name="description" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
            </div>

            <div className="form-text topMargArea">
              <label>Comment</label>
              <textarea className="formTextArea" name="message" value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
            </div>

            <div className="form-text">
              <label>Customer</label>
              <Select
                value={selectedCustomerOption}
                onChange={handleCustomerChange}
                options={customerOptions}
                styles={customSelectStyles}
                required
                isClearable
                isSearchable
                placeholder="Select a Customer"
                noOptionsMessage={() => "No Customers available"}
              />
            </div>

            {/* <div className="form-text">
              <label>Customer name</label>
              <input type="text" name="customerName" value={formData.customerName} onChange={(e) => setFormData({ ...formData, customerName: e.target.value })} />
            </div>

            <div className="form-text">
              <label>Customer email</label>
              <input type="text" name="customerEmail" value={formData.customerEmail} onChange={(e) => setFormData({ ...formData, customerEmail: e.target.value })} />
            </div>

            <div className="form-text">
              <label>Customer address</label>
              <input type="text" name="customerAddress" value={formData.customerAddress} onChange={(e) => setFormData({ ...formData, customerAddress: e.target.value })} />
            </div>

            <div className="form-text">
              <label>Postcode</label>
              <input type="text" name="postcode" value={formData.postcode} onChange={(e) => setFormData({ ...formData, postcode: e.target.value })} />
            </div>

            <div className="form-text">
              <label>Customer mobile phone number</label>
              <input type="text" name="customerMobileNumber" value={formData.customerMobileNumber} onChange={(e) => setFormData({ ...formData, customerMobileNumber: e.target.value })} />
            </div> */}

            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Street Number</label>
                <input value={formData.street_number} type="text" name="street_number" onChange={handleChange} />
              </div>

              <div className="form-text">
                <label>City</label>
                <input value={formData.city} type="text" name="city" onChange={handleChange} />
              </div>
            </div>
            <div className="form-text">
              <label>Street</label>
              <input value={formData.street} type="text" name="street" onChange={handleChange} />
            </div>
            <div className='flex-row gap-10'>
              <div className="form-text">
                <label>Canton</label>
                <Select
                  value={selectedState}
                  onChange={handleStateChange}
                  name="state"
                  options={cantons.cantons.map((canton) => ({ value: canton.name, label: canton.name }))}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  placeholder="Select a Canton"
                  noOptionsMessage={() => "No Canton Available"} />
              </div>
              <div className="form-text">
                <label>Code ZIP</label>
                <input value={formData.zip_code} type="text" name="zip_code" onChange={handleChange} />
              </div>
              {formData.zip_code && (<img src="assets/images/map-icon.svg" alt="Map Icon" className="clickable" onClick={handleShowMap}></img>)}
            </div>

            <div className="form-text" onClick={loadTherapist}>
              <label>Flypro</label>
              <Select
                value={selectedFlyproOption}
                onChange={handleFlyproChange}
                options={flyproOptions}
                styles={customSelectStyles}
                isClearable
                required
                isSearchable
                isMulti
                placeholder="Select a Flypro"
                noOptionsMessage={() => "No Flypros available"}
              />
            </div>

            {/* <div className="form-text">
              <label>Promo CODE</label>
              <input type="text" name="promoCode" value={formData.promoCode} onChange={(e) => setFormData({ ...formData, promoCode: e.target.value })} />
            </div> */}

            {formData.latitude && showMap && (<Map
              coordinates={[coordinates]}
              mode="edit"
              containerStyle={{ width: '100%', height: '400px' }}
              mapOptions={{
                disableDefaultUI: true,
                zoomControl: true,
                mapTypeControl: false,
                fullscreenControl: true,
                streetViewControl: false,
              }}
              onCoordinateChange={handleCoordinateChange}
            />)}
            <button type="submit" className="btn-submit">
              Créer la tâche
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={datePickerIsOpen}
        onRequestClose={closeDatePicker}
        style={timerStyles}
        contentLabel="Date Picker Modal"
      >
        <div className="date-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticDatePicker
              label="Select Date"
              value={value}
              required={1}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              textField={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-date" onClick={closeDatePicker}>Cancel</button>
              <button className="action-date" onClick={handleDatePickerOk}>OK</button>
            </div>
          </LocalizationProvider>
        </div>
      </Modal>

      <Modal
        isOpen={timePickerIsOpen}
        onRequestClose={handleCancelClick}
        style={timerStyles}
        contentLabel="Time Picker Modal"
      >
        <div className="time-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={selectedTime}
              onChange={handleTimeChange}
              textField={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-timer" onClick={handleCancelClick}>Cancel</button>
              <button className="action-timer" onClick={handleOkClick}>OK</button>
            </div>
          </LocalizationProvider>
        </div>
      </Modal>
    </>
  );
};

export default Breadcrumb;
