import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { format, addDays } from 'date-fns';
import image from "../assets/images/profil-picto.png";
import arrowlineleft from "../assets/icons/ArrowLineLeft-s.svg";
import ArrowLineRight from "../assets/icons/ArrowLineRight-s.svg";
import MenuHeader from "./MenuHeader";
import Modal from "react-modal";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from '../utils/utils';
import useAxiosMultiPart from '../hooks/useAxiosMultiPart';
import CalendarManagementService from '../services/calendar_management.service';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Select from 'react-select';
import UserService from '../services/user.service';
import { useNotifications } from '../context/NotificationProvider';
import useAuth from "../hooks/useAuth";
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { CiClock2 } from "react-icons/ci";
import { asTitle } from '../shared/utils/string';

const timerStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    boxshadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    overflowX: 'hidden',
    maxWidth: '350px',
    padding: '0px 0px 0px 0px',
  },
};

const Container = styled.div`
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
  margin-left: 30px;
  margin-top: -80px;
`;

const Sidebar = styled.div`
    top: 145px;
    position: relative;
    width: 100%;
    max-width: 249px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const CalendarContainer = styled.div`
  flex: 1;
  padding: 20px;
  position: relative;
`;

const Header = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    top: 35px;
    left: 30px;
`;

const TimeSlots = styled.div`
  display: grid;
  grid-template-columns: repeat(900, 1fr);
  position: relative;
  top: 60px;
  left: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  background-color: #fff;
  height: 47px;
  padding: 0px 25px;
`;

const TimeSlot = styled.div`
  background-color: ${({ $status }) => {
    if ($status === 'AVAILABLE') return '#96ca63';
    if ($status === 'FREE_TIME') return '#8FA9B9';
    if ($status === 'BOOKED') return '#FF0000';
    if ($status === 'OVER_TIME') return '#ADFF2F';
    if ($status === 'TRAVEL') return '#8A2BE2';
    if ($status === 'BUFFER') return '#CF1020';
    if ($status === 'HOLIDAY') return '#1E88E5';
    if ($status === 'DECLINED' || $status === 'CANCELED') return '#000000';
    return 'transparent';
  }}; 
  margin-bottom: 15px;
  top: 25px !important;
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 8px;
  box-sizing: border-box;
  text-align: left;
  font-size: 12px;
  color: #fff;
  font-family: Inter;
`;

const HourLabel = styled.div`
  width: 50px;
    position: relative;
    font-size: 11px;
    line-height: 16px;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.4);
    text-align: left;
    display: inline-block;
    height: 17px; 
    top: 15px;
    left: 0px;
`;

const TimeText = styled.div`
  color: ${({ status }) => (status === 'OVER_TIME' ? 'black' : 'white')};
  font-size: 14px;
  margin-bottom: 5px;
`;

const DurationText = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: 12px;
  color: ${({ status }) => (status === 'OVER_TIME' ? 'black' : 'white')};
  font-family: Inter;
  margin: 0;
  font-weight: 900;
  font-family: Lato;
`;

const CurrentTimeWrapper = styled.div`
  position: fixed;
  top: ${({ scrollposition }) => (scrollposition > 215 ? 0 : 215 - scrollposition)}px;
  left: calc(169px + ${({ $left }) => $left}%);
  height: 100vh;
  width: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 11;
`;

const CurrentTimeIndicatorLine = styled.div`
  width: 2px;
  background-color: black;
  height: 100%;
`;

const Tooltip = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: -70px;
  background-color: black;
  color: white;
  padding: 5px 8px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-family: Inter, sans-serif;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  ${CurrentTimeWrapper}:hover & {
    display: block;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const generateHours = (startHour, endHour, interval) => {
  const hoursArray = [];
  for (let h = startHour; h <= endHour; h++) {
    for (let m = 0; m < 60; m += interval) {
      const formattedHour = `${h}:${String(m).padStart(2, '0')}`;
      hoursArray.push(formattedHour);
    }
  }
  return hoursArray;
};

const hours = generateHours(8, 22, 1);

const calculateDuration = (start, end) => {
  const parseTime = (timeStr) => {
    let [hour, minute] = timeStr.split(':').map(Number);
    return hour * 60 + minute;
  };

  const startTotalMinutes = parseTime(start);
  const endTotalMinutes = parseTime(end);
  const durationMinutes = endTotalMinutes - startTotalMinutes;
  const totalMinutes = durationMinutes >= 0 ? durationMinutes : (24 * 60) + durationMinutes;

  const durationHours = Math.floor(totalMinutes / 60);
  const durationRemMinutes = totalMinutes % 60;

  return `${durationHours}:${String(durationRemMinutes).padStart(2, '0')}`;
};

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [flypros, setFlypros] = useState([]);
  const [selectedFlyproOption, setSelectedFlyproOption] = useState([]);
  const [timePickerIsOpen, setTimePickerIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(new Date());

  const axiosPrivate = useAxiosPrivate();
  const axiosMultiPart = useAxiosMultiPart();

  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const notify = useNotifications();
  const [formData, setFormData] = useState({
    users: [],
  });
  const { auth } = useAuth();
  const userRoles = auth?.roles;

  const isAdmin = userRoles?.some(role => role.name === "ROLE_ADMIN");


  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [minTime, setMinTime] = useState(new Date('1970-01-01T08:00:00'));
  const [maxTime, setMaxTime] = useState(new Date('1970-01-01T22:00:00'));
  const customSelectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '60px',
      padding: '0 15px',
      fontSize: '14px',
      lineHeight: '60px',
      color: '#393D44',
      border: '0',
      borderRadius: '20px',
      background: '#ffffff',
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setModalIsOpen(false);
    setMessage("");
    setFile(null);
  };
  const handleImport = async () => {
    if (!file) {
      setMessage("Please select a file before importing.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosMultiPart.post(endpoints.importCalender, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setMessage(response.data.message || "File imported successfully.");
      updateCalendar();
    } catch (error) {
      setMessage(
        error.response?.data?.message || "An error occurred during import."
      );
    }
  };

  const handleDownload = async (e) => {
    try {
      e.preventDefault();
      const dates = generateDates();

      if (dates && dates.length) {
        if (formData.users && formData.users.length) {
          const response = await axiosPrivate.post(endpoints.getCalendarFile, {
            users: formData.users,
            dates: dates
          }, {
            responseType: "blob",
          });


          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "downloaded_file.xlsx");
          document.body.appendChild(link);
          link.click();

          link.remove();
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (error) {
    }
  };

  const fetchCalendarData = async (date) => {
    try {
      const response = await CalendarManagementService.index({ date: `${new Date(date).toLocaleDateString('en-CA').split('T')[0]}`, with: 'therapist.media,therapist.zones,calendars_managements_slots.slot' }, axiosPrivate);
      return response;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const fetchAvailibility = async (pro) => {
    try {
      const response = await axiosPrivate.post(
        endpoints.getAvailibility,
        { date: currentDate, pro },

      );

      return response.data;

    } catch (error) {
      console.error('Erreur lors de la récupération des données du calendrier :', error);
      throw error;
    }
  };

  const updateCalendar = async () => {
    try {
      const calendarData = await fetchCalendarData(currentDate);

      const groupedData = calendarData.reduce((acc, item) => {
        const therapistEmail = item.therapist.email;
        const therapistFirstName = item.therapist.firstname;
        const therapistLastName = item.therapist.lastname;
        const therapistCity = item.therapist.primary_address?.city;
        const therapistzip_code = item.therapist.primary_address?.zip_code;

        if (!acc[therapistEmail]) {
          acc[therapistEmail] = {
            ...item.therapist,
            slots: [],
          };
        }
        item.calendars_managements_slots.forEach(calendar_management_slot => {
          acc[therapistEmail].slots.push([
            calendar_management_slot.slot.start_time,
            calendar_management_slot.slot.end_time,
            calendar_management_slot.status,
          ]);
        });

        return acc;
      }, {});
      const therapistEmails = Object.keys(groupedData);


      const adjustAndSplitTimeSlots = (slots) => {
        const minHour = "08:00";
        const maxHour = "18:00";

        // Convert time string to Date object
        const parseTime = (time) => {
          const [hour, minute] = time.split(":").map(Number);
          return new Date(1970, 0, 1, hour, minute);
        };

        // Convert Date object to time string
        const formatTime = (date) => {
          return date.toTimeString().slice(0, 5);
        };

        // Sort slots by start time
        const sortedSlots = slots.sort((a, b) => parseTime(a[0]) - parseTime(b[0]));

        const result = [];
        let previousEndTime = parseTime(minHour);

        for (const [startTime, endTime, status] of sortedSlots) {
          const currentStartTime = parseTime(startTime);

          // Add a FREE_TIME slot if there's a gap
          if (currentStartTime > previousEndTime) {
            result.push([formatTime(previousEndTime), formatTime(currentStartTime), "FREE_TIME"]);
          }

          // Add the current slot
          result.push([startTime, endTime, status]);

          // Update the previous end time
          previousEndTime = parseTime(endTime);
        }

        // Add a final FREE_TIME slot if there's a gap to the maxHour
        if (previousEndTime < parseTime(maxHour)) {
          result.push([formatTime(previousEndTime), maxHour, "FREE_TIME"]);
        }
        return result;
      };


      Object.keys(groupedData).forEach(email => {
        groupedData[email].slots = adjustAndSplitTimeSlots(groupedData[email].slots);
      });

      const formattedData = Object.values(groupedData);
      console.log({ formattedData })
      setTimeSlots(formattedData);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du calendrier :', error);
    }
  };


  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(':');
    const hours12 = (hours % 12) || 12; // Convert 24-hour to 12-hour format
    const period = hours < 12 ? 'AM' : 'PM'; // Determine AM/PM
    return `${hours12}:${minutes} ${period}`;
  }

  useEffect(() => {
    const dateString = format(currentDate, 'yyyy-MM-dd');

    updateCalendar();
  }, [currentDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    setCurrentTime(new Date());

    return () => clearInterval(interval);
  }, []);

  const calculateCurrentTimeLeft = () => {
    const currentHours = currentTime.getHours();
    const currentMinutes = currentTime.getMinutes();
    const currentTotalMinutes = currentHours * 60 + currentMinutes;

    const startTime = 8 * 60;
    const endTime = 22 * 60 + 59;


    if (currentTotalMinutes < startTime || currentTotalMinutes > endTime) {
      return null;
    }

    const totalDisplayedMinutes = 900;
    const elapsedMinutes = currentTotalMinutes - startTime;
    const leftPercentage = (elapsedMinutes / totalDisplayedMinutes) * 100;

    return leftPercentage;
  };

  const currentTimeLeft = calculateCurrentTimeLeft();

  const changeDate = (days) => {
    setCurrentDate((prevDate) => addDays(prevDate, days));
  };

  const renderHourLabels = () => {
    return hours.map((hour, index) => {
      const isHourLabel = index % 60 === 0;

      if (isHourLabel) {
        const displayHour = `${hour}`;

        return (
          <HourLabel key={index}>{displayHour}</HourLabel>
        );
      } else {

        return (
          <div key={index} style={{ height: '40px', width: '0px' }}></div>
        );
      }
    });
  };

  function convertTimeToMinutes(time) {
    const [hours, minutes] = time
      .match(/(\d+):(\d+)/)
      .slice(1)
      .map(Number);
    return (hours % 24) * 60 + minutes;
  }
  const formatTimeTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    if (modifier === 'PM' && hour < 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
  };

  const renderTimeSlots = () => {

    return timeSlots.flatMap((therapist, tIndex) => {
      return therapist.slots.map(([start, end, status], slotIndex) => {
        // Adjust this calculation
        const startIndex = hours.indexOf(start) > 0 ? hours.indexOf(start) : 0;
        const endIndex = hours.indexOf(end);
        // Ensure proper conversion to grid column. Avoiding "off-by-one" errors by adding 1 to the index
        const gridStart = startIndex + 1;
        const gridEnd = endIndex + 1;

        return (
          <TimeSlot
            key={`${tIndex}-${slotIndex}`}
            $status={status}
            style={{ gridColumn: `${gridStart} / ${gridEnd}` }}
          >
            <TimeText status={status}>
              {status === 'HOLIDAY' || status === 'OFF'
                ? asTitle(status)
                : `${asTitle(status)} ${start} - ${end}`}
            </TimeText>
            <DurationText status={status}>
              {status === 'HOLIDAY' || status === 'OFF' ? '' : `${calculateDuration(start, end)}`}
            </DurationText>
          </TimeSlot>
        );
      })
    }
    );
  };

  const closeExportModal = () => {
    setExportModalIsOpen(false);
  }

  const closeCreateModal = () => {
    setCreateModalIsOpen(false);
  }

  const openExportModal = async () => {
    setSelectedFlyproOption([]);
    setFormData({ users: [] });
    setStartDate(new Date());
    setEndDate(new Date());
    const response = await UserService.indexByRole({ status: '!eq:BLOCKED' }, 'ROLE_FLYPRO', axiosPrivate);
    setFlypros(response);
    setExportModalIsOpen(true);
  }

  const openCreateModal = async () => {
    setSelectedFlyproOption([]);
    setFormData({ users: [] });
    setStartDate(new Date());
    setEndDate(new Date());
    setSlots([{ start_time: '08:00', end_time: '12:00', status: 'AVAILABLE' }])
    const response = await UserService.indexByRole({ status: '!eq:BLOCKED' }, 'ROLE_FLYPRO', axiosPrivate);
    setFlypros(response);
    setCreateModalIsOpen(true);
  }

  const handleCancelClick = () => {
    setTimePickerIsOpen(false);
  };

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  const handleOkClick = () => {
    const timeString = selectedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const newSlots = slots.map((slot, index) => index !== selectedSlot.index ? slot :
      {
        ...slot,
        start_time: selectedSlot.is_start ? formatTimeTo24Hour(timeString) : slot.start_time,
        end_time: selectedSlot.is_end ? formatTimeTo24Hour(timeString) : slot.end_time,
      });
    setSlots(newSlots)
    setTimePickerIsOpen(false);
  };

  const generateDates = () => {
    if (!startDate || !endDate || startDate > endDate) {
      notify.warn('Please select a valid date range');
      return;
    }

    const dates = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      // Exclude Sundays (0 = Sunday)
      if (currentDate.getDay() !== 0) {
        dates.push(new Date(currentDate).toLocaleDateString('en-CA').split('T')[0]);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const isSundayOrPast = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize to midnight for accurate comparison
    return date < today || date.getDay() === 0; // Disable past dates or Sundays
  };

  // Function to disable Sundays, dates in the past, and dates before the start date
  const isSundayOrBeforeStartDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    startDate?.setHours(0, 0, 0, 0);
    if (!startDate) return isSundayOrPast(date); // No start date selected
    return date < today || date < startDate || date.getDay() === 0; // Disable past, before start date, and Sundays
  };

  const flyproOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.email, label: row.extra?.full_name })) : [];
  const flyproIdOptions = flypros?.length > 0 ? flypros.map(row => ({ value: row.id, label: row.extra?.full_name })) : [];

  const handleFlyproChange = (selected) => {
    if (selected?.length) {
      setSelectedFlyproOption(selected);
      setFormData((prev) => ({
        ...prev,
        users: selected.map((therapist) => therapist.value)
      }));
    } else {
      setSelectedFlyproOption([]);

      setFormData((prev) => ({
        ...prev,
        users: []
      }));
    }
  };

  const handleCreateSlots = async (e) => {
    e.preventDefault();
    const dates = generateDates();
    const therapists = selectedFlyproOption.map(flypro => flypro.value)
    console.log({slots, therapists, dates})
    const response = await CalendarManagementService.createSlots({
      slots,
      therapists,
      dates
    }, axiosPrivate);
    updateCalendar();
    setCreateModalIsOpen(false)
  }

  const [scrollposition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <MenuHeader />
      {isAdmin && (
        <div className="bloc-search">
          <div className="container">
            <div className="search" />

            <div className='actions'>
              <button onClick={openExportModal} className="btn-download">
                Download template
              </button>
              <button onClick={openModal} className="btn-add-client">
                Import
              </button>
              <button onClick={openCreateModal} className="btn-add-client">
                Create
              </button>
            </div>

          </div>
        </div>
      )}

      <Header>
        <div className="group-cal">
          <div className="button-cal">
            <div className="iconset-cal">
              <img
                onClick={() => changeDate(-1)}
                className="arrowlineleft-s-icon-cal"
                alt="Previous Day"
                src={arrowlineleft}
              />
            </div>
          </div>
          <div className="text-cal">
            <div className="text1-cal">
              {currentDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
            </div>
          </div>
          <div className="button-cal">
            <div className="iconset-cal">
              <img
                onClick={() => changeDate(1)}
                className="arrowlineleft-s-icon-cal"
                alt="Next Day"
                src={ArrowLineRight}
              />
            </div>
          </div>
          <div className="button2-cal">
            <div className="text-cal">
              <div className="text1-cal" onClick={() => setCurrentDate(new Date())}>
                Today
              </div>
            </div>
          </div>
        </div>
      </Header>

      <Container>
        <Sidebar>
          {timeSlots.map((therapist, index) => {

            const totalHours = therapist?.slots
              ?.reduce((total, [start, end]) => {
                const startMinutes = convertTimeToMinutes(start);
                const endMinutes = convertTimeToMinutes(end);

                const durationInHours = (endMinutes - startMinutes) / 60;

                return total + durationInHours;
              }, 0)
              .toFixed(2);
            const totalAvailableHours = therapist?.slots
              ?.filter(([start, end, status]) => status === "AVAILABLE" || status === "OVER_TIME")
              ?.reduce((total, [start, end]) => {

                const startMinutes = convertTimeToMinutes(start);
                const endMinutes = convertTimeToMinutes(end);


                const durationInHours = (endMinutes - startMinutes) / 60;

                return total + durationInHours;
              }, 0)
              .toFixed(2);

            const totalNonAvailableHours = therapist?.slots
              ?.filter(([start, end, status]) => status !== "AVAILABLE" && status !== "OVER_TIME")
              ?.reduce((total, [start, end]) => {

                const startMinutes = convertTimeToMinutes(start);
                const endMinutes = convertTimeToMinutes(end);


                const durationInHours = (endMinutes - startMinutes) / 60;

                return total + durationInHours;
              }, 0)
              .toFixed(2);

            const totalBookedHours = therapist?.slots
              ?.filter(([start, end, status]) => status === "BOOKED")
              ?.reduce((total, [start, end]) => {
                const startMinutes = convertTimeToMinutes(start);
                const endMinutes = convertTimeToMinutes(end);

                const durationInHours = (endMinutes - startMinutes) / 60;


                return total + durationInHours;
              }, 0)
              .toFixed(2);

            return (
              <div key={index} className="calendar-therapist">
                <div className="calendar-therapist-item">
                  <div className="calendar-therapist-item-header">

                    <img src={therapist.media?.[0]?.original_url ?? image} alt="avatar" className="avatar-img" />
                    <div className="therapist-item-title">
                      <div className="calendar-therapist-name">
                        {therapist.firstname} {therapist.lastname}
                      </div>
                      <div className="calendar-therapist-zone">
                        {therapist.zones.length ? therapist.zones?.map(zone => zone.name).join(', ') : '-'}
                      </div>
                    </div>
                  </div>
                  <div className="calendar-therapist-availibility">
                    <div className="details-items">
                      <p className="availability-therapist">Available: {totalAvailableHours} Hrs</p>
                      <p className="availability-therapist">Booked Time: {totalBookedHours} Hrs</p>
                      <p className="availability-therapist">
                        Availability: {(((parseFloat(totalAvailableHours)) / (parseFloat(totalHours))) * 100).toFixed(2)}
                        %
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        </Sidebar>

        <CalendarContainer>
          <TimeSlots>
            {renderHourLabels()}
            {renderTimeSlots()}

            {currentTimeLeft !== null && (
              <CurrentTimeWrapper $left={currentTimeLeft} scrollposition={scrollposition}>
                <CurrentTimeIndicatorLine />
                <Tooltip>
                  {format(currentTime, 'hh:mm')}
                </Tooltip>
              </CurrentTimeWrapper>
            )}
          </TimeSlots>
        </CalendarContainer>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Import Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Import File</h2>
        <div className="modal-content">
          <input type="file" onChange={handleFileChange} />
          <button onClick={handleImport} className="btn-import">
            Upload
          </button>
          <button onClick={closeModal} className="btn-cancel">
            Cancel
          </button>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>
      <Modal
        isOpen={exportModalIsOpen}
        onRequestClose={closeExportModal}
        contentLabel="Import Modal"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Export Template File</h2>
        <div className="form-inscription">
          <form onSubmit={handleDownload}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className='flex-row align-center space-around'>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrPast(date)} // Disable Sundays for start date
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrBeforeStartDate(date)} // Disable dates before start date and Sundays
                />
              </div>
            </LocalizationProvider>
            <div className='flex-row'>
              <div className="form-text">
                <label>Therapists*</label>
                <Select
                  value={selectedFlyproOption}
                  onChange={handleFlyproChange}
                  options={flyproOptions}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  isMulti
                  placeholder="Select Therapists"
                  noOptionsMessage={() => "No therapist available"}
                />
              </div>
            </div>
            <button type='submit' className="btn-submit">Download</button>
          </form>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>

      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={closeCreateModal}
        contentLabel="Create Slots"
        ariaHideApp={false}
        style={customStyles}
      >
        <h2>Create Slots</h2>
        <div className="form-inscription">
          <form onSubmit={handleCreateSlots}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className='flex-row align-center space-around'>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrPast(date)} // Disable Sundays for start date
                />
                <DatePicker
                  label="End Date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  shouldDisableDate={(date) => isSundayOrBeforeStartDate(date)} // Disable dates before start date and Sundays
                />
              </div>
            </LocalizationProvider>
            <div className='flex-row'>
              <div className="form-text">
                <label>Therapists*</label>
                <Select
                  value={selectedFlyproOption}
                  onChange={handleFlyproChange}
                  options={flyproIdOptions}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  isMulti
                  placeholder="Select Therapists"
                  noOptionsMessage={() => "No therapist available"}
                />
              </div>
            </div>
            <h3>Slots *</h3>

            {slots.map((slot, index) => (<div className='flex-row align-center' key={index}>
              <div className="form-text timeOfTheBookingParent topMarg">
                <label>Start Time</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={slot.start_time}
                  onClick={() => {
                    setSelectedSlot({ index, is_start: true, is_end: false });
                    const min = index === 0 ? new Date('1970-01-01T08:00:00') : new Date(`1970-01-01T${slots[index - 1].end_time}:00`)
                    const max = slots[index].end_time ? new Date(`1970-01-01T${slots[index].end_time}:00`) : new Date('1970-01-01T22:00:00');
                    max.setMinutes(max.getMinutes() - 15);
                    setMinTime(min);
                    setMaxTime(max);
                    setTimePickerIsOpen(true);
                  }}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => { setTimePickerIsOpen(true) }} />
              </div>
              <div className="form-text timeOfTheBookingParent topMarg">
                <label>End Time</label>
                <input
                  type="text"
                  name="timeBooking"
                  value={slot.end_time}
                  onClick={() => {
                    setSelectedSlot({ index, is_end: true, is_start: false });
                    const min = slots[index].start_time ? new Date(`1970-01-01T${slots[index].start_time}:00`) : new Date('1970-01-01T08:00:00');
                    min.setMinutes(min.getMinutes() + 15);
                    const max = new Date('1970-01-01T22:00:00');
                    setMinTime(min);
                    setMaxTime(max);
                    setTimePickerIsOpen(true);
                  }}
                  readOnly
                />
                <CiClock2 className="time-icon" onClick={() => setTimePickerIsOpen(true)} />
              </div>
              <div className="form-text">
                <label>Status*</label>
                <Select
                  value={{ label: asTitle(slot.status), value: slot.status }}
                  onChange={(status) => {
                    const newSlots = slots.map((slot, i) => index !== i ? slot :
                      { ...slot, status: status.value }
                    );
                    setSlots(newSlots);
                  }}
                  options={[{ label: 'Available', value: 'AVAILABLE' }, { label: 'Free Time', value: 'FREE_TIME' }, { label: 'Overtime', value: 'OVER_TIME' }, { label: 'Holiday', value: 'HOLIDAY' }, { label: 'Day Off', value: 'OFF' }]}
                  styles={customSelectStyles}
                  isClearable
                  required
                  isSearchable
                  placeholder="Select Status"
                  noOptionsMessage={() => "No Status available"}
                />
              </div>
              <button type="button" className="close-button" style={{ border: 'none', height: 'fit-content' }} onClick={() => {
                setSlots(slots.filter((slot, i) => index !== i));
              }}>
                <span className="close-icon">✖</span>
              </button>
            </div>))}
            <button type='button' className="btn-submit" onClick={() => {
              if (slots[slots.length - 1].end_time == '22:00') {
                notify.warn('You can\'t add a slot after 22:00')
              } else {
                setSlots([...slots, { start_time: slots[slots.length - 1].end_time, end_time: '22:00', status: 'AVAILABLE' }])
              }
            }}>Add Slot</button>

            <button type='submit' className="btn-submit">Create Slots</button>
          </form>
        </div>
        {message && <div className="message">{message}</div>}
      </Modal>
      <Modal
        isOpen={timePickerIsOpen}
        onRequestClose={handleCancelClick}
        style={timerStyles}
        contentLabel="Time Picker Modal"
      >
        <div className="time-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={selectedTime}
              ampm={false}
              minTime={minTime}
              maxTime={maxTime}
              onChange={handleTimeChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="pickerActions">
              <button className="action-timer" onClick={handleCancelClick}>Cancel</button>
              <button className="action-timer" onClick={handleOkClick}>OK</button>
            </div>
          </LocalizationProvider>
        </div>
      </Modal>
    </>
  );
};

export default Calendar;