import ApiService from "./api.service";
export default class CalendarManagementService extends ApiService {
    static base_url = `${super.base_url}/calendars_managements`;

    static async downloadTemplate(axiosInstance) {
        const response = await axiosInstance.get(`${this.base_url}/download_schedule_template`);
        return response.data;
    }

    static async createSlots(data, axiosInstance) {
        const response = await axiosInstance.post(`${this.base_url}/slots`, data);
        return response.data;
    }
}
