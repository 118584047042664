import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { endpoints } from "../utils/utils";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";


const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const userRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");



  const [passwordType, setPasswordType] = useState("password");
  const [faClass, setFaClass] = useState("fa-regular fa-eye");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      setFaClass("fa-regular fa-eye-slash");
      return;
    }
    setPasswordType("password");
    setFaClass("fa-regular fa-eye");
  };


  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    /*  console.log("params", params.id); */

    try {
      const response = await AuthService.login({ email: user, password: pwd }, axiosPrivate);
      console.log({response})
      const accessToken = response?.token;
      const roles = response?.user?.roles;
      const refresh = response?.refresh_token;
      const active = response?.user?.status === 'ACTIVE';
      localStorage.setItem("accessToken", response?.token);
      setAuth({ user: response?.user, roles, accessToken, refresh, active });
      setUser("");
      setPwd("");

      setTimeout(() => {
             UserService.update(response?.user?.id,{ fcm_token_web: localStorage.getItem("firebaseToken")}, axiosPrivate  )
          }, 3000);
      navigate("/", { replace: true });
    } catch (err) {
      console.log(err)
    }
  };



  return (
    <div className="main-login">
      <div className="container">
        <div className="admin-left">
          <div className="logo">Flyspa</div>
          <div className="form-login">

            <div className="text-ou"></div>
            <form name="" id="" onSubmit={handleSubmit}>
              {errMsg ? <p className="error">{errMsg}</p> : ""}
              <div className="form-text">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Name@mail.com"
                  className="email"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />
              </div>
              <div className="form-text">
                <input
                  type={passwordType}
                  onChange={(e) => setPwd(e.target.value)}
                  name="password"
                  id="password"
                  value={pwd}
                  placeholder="Password"
                  className="password"
                  required
                />
                <div onClick={togglePassword} className="eye">
                  <a
                    href="#0"
                    onClick={togglePassword}
                    role="button"
                    tabIndex="0"
                  >
                    <i onClick={togglePassword} className={faClass}></i>
                  </a>
                </div>
              </div>
              <Link to="/forget_password" className="pass-oublie">
                Mot de passe oublié ?
              </Link>
              <div className="form-submit">
                <input type="submit" name="sign" id="sign" value="Connexion" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
