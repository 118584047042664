import ApiService from "./api.service";
export default class UserService extends ApiService {
    static base_url = `${super.base_url}/users`;

    // GET a resource by ID with optional query parameters
    static async currentUser(axiosInstance) {
        const response = await axiosInstance.get(`${super.base_url}/auth/me?with=roles`);
        return response.data;
    }

    static async indexByRole(params, role, axiosInstance) {
        const queryString = this.buildParams(params);

        const response = await axiosInstance.get(`${this.base_url}/role/${role}?${queryString}`);
        return response.data;
    }

    static async fetchAvailableTherapists(data, params, axiosInstance) {
        const queryString = this.buildParams(params);

        const response = await axiosInstance.post(`${super.base_url}/therapists?${queryString}`, data);
        return response.data;
    }
}
