export const setupNotificationInterceptor = (axiosInstance, notify) => {

    const requestIntercept = axiosInstance.interceptors.request.use(config => {
        return config;
    }, error => {
        console.log({ error });
        return Promise.reject(error);
    });

    const responseIntercept = axiosInstance.interceptors.response.use(response => {
        if (response.data.hasOwnProperty('message')) {
            notify.success(response.data.message);
        }
        return response;
    }, async error => {
        console.log({error})
        if (error?.code === "ERR_NETWORK") {
            notify.error(error.message);
        } else {
            console.log(error)
            if (error.status === 422 && error.response?.data?.errors) {
                for (const field in error.response.data.errors) {
                    error.response.data.errors[field].forEach(error => {
                        notify.error(error)
                    });
                }
            }
            else if (error?.response?.data?.message) {
                notify.error(error.response.data.message);
            }

        }
        return Promise.reject(error);
    });

    // Cleanup function to remove interceptors
    return () => {
        axiosInstance.interceptors.request.eject(requestIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};
