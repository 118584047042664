import React, { useState, useEffect, useRef } from "react";
import "./ListItem.css";
import ListImage from "../ListItemImage/ListItemImage";
import Commande from "../Commande/Commande";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import BookingService from "../../services/booking.service";
import { castDate } from "../../shared/utils/string";
import useAuth from "../../hooks/useAuth";

const ListItem = ({ date, time, title, description, status, axiosPrivate }) => (
  <div className="item">
    <div className="item-content">
      <div className="date">
        <span>
          <span>{date}</span>
          <span> | </span>
          <span>{time}</span>
        </span>
      </div>
      <div className="item_title">{title}</div>
      <div className="item_description">{description}</div>
    </div>
    <div className="status-container">
      <div className="status-badge">
        <div className="status-label">{status}</div>
      </div>
    </div>
  </div>
);

export default function List() {
  const [activeTab, setActiveTab] = useState("Not Assigned");
  const [selectedItem, setSelectedItem] = useState({ data: null });
  const [showCommande, setShowCommande] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [bookings, setBookings] = useState([]);
  const [items, setItems] = useState([]);
  const commandeRef = useRef(null);
  const { auth } = useAuth();
  const userRoles = auth?.roles;


  const fetchBookings = async () => {
    try {
      if (!userRoles?.some(role => role.name === "ROLE_ADMIN")) {
        const response = await BookingService.index({
          with: 'therapists,creator,client.primary_address,history', start_before: `contains:${new Date().toLocaleDateString('en-CA').split('T')[0]}`
        }, axiosPrivate);
        setBookings(response);
        const formattedBookings = response.flatMap((booking) => {
          if (!booking.therapists.length) {
            // No therapists assigned
            return [{
              booking: booking,
              date: castDate(booking.date),
              time: "",
              title: booking.client.extra.full_name,
              description: booking.description,
              status: "Not Assigned",
            }];
          } else {
            return booking.therapists.map((therapist) => ({
              booking: booking,
              therapist: therapist,
              date: castDate(booking.date),
              time: "",
              title: booking.client.extra.full_name,
              description: booking.description,
              status: "Assigned",
            }));
          }
        });
        console.log({ formattedBookings })
        setItems(formattedBookings);
      } else {
        const response = await BookingService.index({ with: 'therapists,creator,client.primary_address,history', start_before: `contains:${new Date().toLocaleDateString('en-CA').split('T')[0]}` }, axiosPrivate);
        setBookings(response);
        const formattedBookings = response.flatMap((booking) => {
          if (!booking.therapists.length) {
            // No therapists assigned
            return [{
              booking: booking,
              date: castDate(booking.date),
              time: "",
              title: booking.client.extra.full_name,
              description: booking.description,
              status: "Not Assigned",
            }];
          } else {
            return booking.therapists.map((therapist) => ({
              booking: booking,
              therapist: therapist,
              date: castDate(booking.date),
              time: "",
              title: booking.client.extra.full_name,
              description: booking.description,
              status: "Assigned",
            }));
          }
        });
        console.log({ formattedBookings })
        setItems(formattedBookings);
      }


      // setItems([...response.find((booking) => !booking.therapists.lenght).map((booking) => ({
      //   date: castDate(booking.date),
      //   time: "",
      //   title: booking.client.extra.full_name,
      //   description: booking.description,
      //   status: "Not Assigned",
      // })), ...response.find((booking) => booking.therapists.lenght).map(booking => {

      // })])
    } catch (e) {

    }
  }
  useEffect(() => {
    fetchBookings();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commandeRef.current && !commandeRef.current.contains(event.target)) {
        setShowCommande(false);
      }
    };

    if (showCommande) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCommande]);
  // const items = [
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  //   {
  //     date: "04/07/2024",
  //     time: "11:00 - 12:30",
  //     title: "Forem ipsum",
  //     description: "Porem ipsum dolor sit amet",
  //     status: "Not Assigned",
  //   },
  // ];

  const filteredItems = items.filter((item) => item.status === activeTab);

  return (
    <div className="list-item-with-image">
      <div className="frame-14">
        <div className="title">Orders</div>
        <div className="glyph" />
      </div>
      <div className="tabs">
        <div className="tab-group">
          <div
            className={`tab-1 ${activeTab === "Not Assigned" ? "active" : ""}`}
            onClick={() => setActiveTab("Not Assigned")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Not Assigned</div>
                {activeTab === "Not Assigned" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`tab-2 ${activeTab === "Assigned" ? "active" : ""}`}
            onClick={() => setActiveTab("Assigned")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Assigned</div>
                {activeTab === "Assigned" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={`tab-3 ${activeTab === "Terminé" ? "active" : ""}`}
            onClick={() => setActiveTab("Terminé")}
          >
            <div className="state-layer">
              <div className="tab-contents">
                <div className="label2">Terminé</div>
                {activeTab === "Terminé" && (
                  <div className="indicator">
                    <div className="shape"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeTab === "Not Assigned" && (
        <div className="list-items">
          {filteredItems.map((item, index) => (
            <ListItem
              key={index}
              date={item.date}
              time={item.time}
              title={item.title}
              description={item.description}
              status={item.status}
            />
          ))}
        </div>
      )}
      {activeTab === "Assigned" && (
        <div className="list-items">
          {filteredItems.map((item, index) => (
            <div
              className="list-item-container"
              key={index}
              onClick={() => {
                setSelectedItem(item);
                setShowCommande(true);
              }}
            >
              <ListImage
                date={item.date}
                time={item.time}
                title={item.title}
                description={item.description}
                status={item.status}
              />
            </div>
          ))}
        </div>
      )}

      {activeTab === "Terminé" && <div>Done !</div>}

      {showCommande && selectedItem && (
        <div className="commande-container" ref={commandeRef}>
          <Commande selectedItem={selectedItem} />
        </div>
      )}
    </div>
  );
}
