import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useRef } from "react";
import useLogout from '../hooks/useLogout';
import useAuth from "../hooks/useAuth";

const MenuHeader = () => {
  const location = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const logout = useLogout();
  const { auth } = useAuth();
  const timeoutRef = useRef(null);

  const signOut = async () => {
    await logout();
    navigate('/login');
  }


  const getMenuItem = () => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get('tab');
    switch (location.pathname) {
      case '/':
        return 'Dashboard';
      case '/tasks':
        return 'Bookings';
      case '/customer':
        return 'Customers';
      case '/flypro':
        return 'flypro';
      case '/calendar':
        return 'Calendar Management';
      case '/managers':
        return 'Managers';
      case '/products':
        return 'Domain & Products';
      case '/manager-products':
        return 'Products';
      case '/hotels':
        return 'Hotel Mgnt';
      case '/notification':
        return 'Notification';
      case '/promotion':
        return 'Promotion';
      case '/statistic':
        return 'Statistic';
      case '/Management':
        return 'Setting';
      case '/management':
        // Check if "tab=zone-management" is present in the query string
        if (tab === 'zone-management') {
          return 'Zone Management';
        }
        return 'Profile Setting';
      default:
        return 'Home';
    }
  };

  const handleMenuClick = () => {
    console.log()
    setIsSubMenuOpen((prev) => !prev);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsSubMenuOpen(false);
    }, 250);
  };

  return (
    <div className="menu-header">
      <div className="container">
        <div className="menu logo-container">
          <div className="menu-right">
            <ul>
              <li
                className="parent"
                onMouseLeave={handleMouseLeave}
              >
                <a
                  href="#"
                  className="burger"
                  onClick={handleMenuClick}
                >
                </a>
                {isSubMenuOpen && (
                  <>
                    {auth?.roles?.some(role => role.name === 'ROLE_ADMIN') && (
                      <ul className="sub-menu">
                        <li><a href="/">Dashboard</a></li>
                        <li><a href="/tasks">Booking</a></li>
                        <li><a href="/customer">Customers</a></li>
                        <li><a href="/flypro">flypro</a></li>
                        <li><a href="/calendar">Calendar Mgnt</a></li>
                        <li><a href="/managers">Managers</a></li>
                        <li><a href="/products">Domain & Products</a></li>
                        <li><a href="/hotels">Hotel Mgnt</a></li>
                        <li><a href="/notification">Notification</a></li>
                        <li><a href="/promotion">Promotion</a></li>
                        <li><a href="#">Statistic</a></li>
                        <li>
                          <a
                            href="#"
                            onClick={() => navigate('/management?tab=zone-management')}
                          >
                            Zone Management
                          </a>
                        </li>
                        <li><a href="/Management">Setting</a></li>
                        <li><a href="#" onClick={signOut}>Logout</a></li>
                      </ul>
                    )}

                    {auth?.roles?.some(role => role.name === 'ROLE_HOTEL_MANAGER') && (
                      <ul className="sub-menu">
                        <li><a href="/">Dashboard</a></li>
                        <li><a href="/tasks">Booking</a></li>
                        <li><a href="/calendar">Calendar Mgnt</a></li>
                        <li><a href="/manager-products">Products</a></li>
                        <li><a href="/management?tab=profile">My profile</a></li>
                        <li><a href="#" onClick={signOut}>Logout</a></li>
                      </ul>
                    )}
                  </>
                )}
              </li>
            </ul>
          </div>
          <a href="/" className="logo">Flyspa</a>

        </div>
        <div className="menu">
          <div className="menu-left">
            <ul>
              <li><a href="#">{getMenuItem()}</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <ul>
              <li><a href="#" className="notif"><span>3</span></a></li>
              <li><a href="/management" className="account">{auth?.user?.extra?.initials}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
