import React, { useState, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

const defaultContainerStyle = {
  width: '100vw',
  height: '150vh',
};

const defaultOptions = {
  disableDefaultUI: true,
  zoomControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
};

export const Map = ({
  coordinates = [], // List of coordinates
  mode = 'view', // Can be 'view' or 'edit'
  onCoordinateChange = () => {}, // Callback to return updated coordinates
  mapOptions = defaultOptions, // Configurable map options
  containerStyle = defaultContainerStyle, // Parametrable container style with default values
}) => {
  const [markers, setMarkers] = useState(coordinates);

  const handleDragEnd = useCallback(
    (event, index) => {
      const updatedCoordinates = [...markers];
      updatedCoordinates[index] = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setMarkers(updatedCoordinates);
      onCoordinateChange(updatedCoordinates);
    },
    [markers, onCoordinateChange]
  );

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={containerStyle} // Use the parametrable containerStyle
        center={mode === 'edit' && markers.length === 1 ? markers[0] : markers[0] || { lat: 46.356, lng: 6.5641 }}
        zoom={11}
        options={mapOptions}
      >
        {markers.map((coordinate, index) => (
          <Marker
            key={index}
            position={coordinate}
            draggable={mode === 'edit' && markers.length === 1} // Draggable only in edit mode with one marker
            onDragEnd={(event) => handleDragEnd(event, index)}
          />
        ))}
      </GoogleMap>
    </div>
  );
};
