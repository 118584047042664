import React, { useEffect, useState, useRef } from "react";
import MenuHeader from "./MenuHeader";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Modal from "react-modal";
import Typography from "@mui/material/Typography";
import imageFile from "../assets/images/upload-file.svg";
import useAxiosMultiPart from "../hooks/useAxiosMultiPart";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import ProductService from "../services/product.service";
import DomainService from "../services/domain.service";
import CategoryService from "../services/category.service";
import { asTitle } from "../shared/utils/string";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    boxshadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    overflowX: "hidden",
    scrollbarWidth: "none",
    height: "680px",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8fa9b9",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const Product = () => {
  Modal.setAppElement("#root");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [products, setProducts] = useState([]);
  const [domain, setDomain] = useState([]);
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [productList, setProductList] = useState([]);
  const axiosMultiPart = useAxiosMultiPart();
  const axiosPrivate = useAxiosPrivate();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [hoveredRow, setHoveredRow] = useState(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [filterData, setFilterData] = useState({
    search: "",
    domain: "",
    category: "",
    duration: "",
    is_active: "",
  });

  const [productFilters, setProductFilters] = useState({});
  const [filtersProduct, setFiltersProducts] = useState({});
  const [domainFilters, setDomainFilters] = useState({});
  const [categoryFilters, setCategoryFilters] = useState({});

  const [formData, setFormData] = useState({
    id: "",
    category_id: "",
    domain_id: "",
    subcategory_id: "",
    product_id: "",
    parent_id: "",
    parent_category_id: "",
    name: "",
    title: "",
    duration: "",
    type: "product",
    is_active: true,
    price: "",
    description: "",
    subproduct: "",
  });

  // Added state to track view type
  const [viewType, setViewType] = useState("product");
  const [domainsData, setDomainsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [sortField, setSortField] = useState(""); // Active sort field
  const [sortDirection, setSortDirection] = useState(true);
  const timeoutRef = useRef(null);

  const handleViewTypeChange = async (value) => {
    setViewType(value);
    setFilterData({
      search: "",
      domain: "",
      category: "",
      duration: "",
      is_active: "",
      is_domain_active: "",
      is_category_active: "",
    });
    await fetchData(value); // Fetch data with relevant filters
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (formData.type === "product" || formData.type === "subproduct" || formData.type === "subcategory" || formData.type === "category") {
      if (name === "domain_id") {
        console.log('change')
        try {
          if (value !== "") {
            const response = await CategoryService.index({ domain_id: value, parent_id: null, status: 'ACTIVE' }, axiosPrivate);
            setCategories(response);
          } else {
            setCategories([]);
          }
        } catch (error) {
        }
      }

      // Handle category selection
      if (name === "parent_category_id") {
        try {
          // Fetch subcategories by category ID
          if (value !== "") {
            const response = await CategoryService.index({ parent_id: value, status: 'ACTIVE' }, axiosPrivate);
            setSubCategories(response);
          } else {
            setSubCategories([]);
          }
        } catch (error) {
        }
      }

      // Handle subcategory selection
      if (formData.type === "subproduct" && name === "category_id") {
        try {
          if (value !== "") {
            const response = await ProductService.index({ category_id: value, parent_id: null, status: 'ACTIVE' }, axiosPrivate);
            setProductList(response);
          } else {
            setProductList([]);
          }

        } catch (error) {
        }
      }
    }

    // Handle file input
    if (name === "file") {
      console.log({ e });
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "type") {
      resetForm(value)
    }
  };

  const resetForm = (type = 'product') => {
    setFormData({
      id: "",
      category_id: "",
      domain_id: "",
      subcategory_id: "",
      product_id: "",
      parent_id: "",
      parent_category_id: "",
      name: "",
      title: "",
      duration: "",
      type: type,
      is_active: true,
      price: "",
      description: "",
      subproduct: "",
    });

    // Reset the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setFileName("");
  };

  const fetchData = async (type = viewType, filters = {}) => {
    try {
      const updatedFilters = {
        ...filters,
        page: currentPage,
        per_page: perPage,
      };

      if (type === "product") {
        const [response, domaineResponse, categoryResponse] = await Promise.all([
          ProductService.index({ with: "media,category.parent,domain,sub_products,parent,hotel", ...updatedFilters }, axiosPrivate),
          DomainService.index(null, axiosPrivate),
          CategoryService.index({ with: "parent" }, axiosPrivate),
        ]);
        setProducts(response.data);
        setDomain(domaineResponse);
        setCategory(categoryResponse);
        setTotal(response.total);
        setLastPage(response.last_page);
        if (response.last_page < currentPage) {
          setProductFilters({ ...filters, page: response.last_page })
          setFiltersProducts({ ...filters, page: response.last_page })
          setCurrentPage(response.last_page);
        }
      } else if (type === "domain") {
        const domainResponse = await DomainService.index({ ...updatedFilters, with: "media" }, axiosPrivate);
        setDomainsData(domainResponse.data);
        setDomain(domainResponse.data);
        setTotal(domainResponse.total);
        setLastPage(domainResponse.last_page);
        if (domainResponse.last_page < currentPage) {
          setDomainFilters({ ...filters, page: domainResponse.last_page })
          setCurrentPage(domainResponse.last_page);
        }
      } else if (type === "category") {
        const categoriesResponse = await CategoryService.index({ with: "media,parent,domain", ...updatedFilters }, axiosPrivate);
        setCategoriesData(categoriesResponse.data);
        setCategory(categoriesResponse.data);
        setTotal(categoriesResponse.total);
        setLastPage(categoriesResponse.last_page);
        if (categoriesResponse.last_page < currentPage) {
          setCategoryFilters({ ...filters, page: categoriesResponse.last_page })
          setCurrentPage(categoriesResponse.last_page);
        }
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    fetchData(viewType, filtersProduct);
  }, [currentPage, perPage]);

  const handleSwitchChange = (index, row) => async (event) => {
    await ProductService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
    fetchData("product");
  };

  const handleFormSwitchChange = (e) => {
    setFormData({ ...formData, is_active: !formData.is_active })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log((formData.type))
    // Créer une nouvelle instance de FormData
    const formDataToSend = new FormData();
    // Ajouter les données de formData (y compris les fichiers) à formDataToSend
    for (const key in formData) {
      const value = formData[key];
      if (value) {
        if (Array.isArray(value) || typeof value === 'object') {
          formDataToSend.append(key, JSON.stringify(value));
        } else if (typeof value === 'boolean') {
          formDataToSend.append(key, Number(value)); // Convert boolean to string
        } else {
          formDataToSend.append(key, value);
        }
      }
    }
    console.log({ file })
    // Si vous avez un fichier dans un champ 'file', vous devez l'ajouter explicitement
    if (file) {
      formDataToSend.append("imageFile", file);
    } else {
      if (!formData.id && formData.type !== "product") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Merci de selectionner un logo ",
        });
        return;
      }

    }
    for (const pair of formDataToSend.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    console.log(file ? 'axiosMultiPart' : 'axiosPrivate')
    if (formData.id) {
      switch (formData.type) {
        case 'product':
          ProductService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'category':
          CategoryService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'subcategory':
          CategoryService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        case 'subproduct':
          ProductService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
        default:
          DomainService.updateWithImage(formData.id, file ? formDataToSend : formData, file ? axiosMultiPart : axiosPrivate)
          break;
      }
      closeModal();
      setTimeout(() => fetchData(viewType), 100);
      resetForm();

    } else {
      try {
        switch (formData.type) {
          case 'product':
            await ProductService.store(formDataToSend, axiosMultiPart);
            break;
          case 'subproduct':
            await ProductService.store(formDataToSend, axiosMultiPart);
            break;
          case 'category':
            await CategoryService.store(formDataToSend, axiosMultiPart);
            break;
          case 'subcategory':
            await CategoryService.store(formDataToSend, axiosMultiPart);
            break;
          default:
            await DomainService.store(formDataToSend, axiosMultiPart);
            break;
        }
        closeModal();
        fetchData(viewType);
      } catch { }
    }
  };

  async function openModal(data, type, editType) {
    resetForm();
    const domain_id = editType !== 'domain' ? data?.domain_id || "" : "";
    const category_id = editType === 'product' || editType === 'subproduct' ? data?.category?.parent_id || "" : editType === 'subcategory' ? data?.parent_id || "" : "";
    const subcategory_id = editType === 'product' || editType === 'subproduct' ? data?.category_id || "" : "";
    const product_id = editType === 'subproduct' ? data?.parent_id || "" : "";

    if (domain_id && domain_id != '') {
      try {
        const response = await CategoryService.index({ domain_id: domain_id, status: 'ACTIVE' }, axiosPrivate);
        console.log('CategoryByDomain : ', response);
        setCategories(response);
      } catch (error) {

      }
    }

    if (category_id && category_id != '') {
      try {

        const response = await CategoryService.index({ parent_id: category_id, status: 'ACTIVE' }, axiosPrivate);
        setSubCategories(response);

      } catch (error) {

      }
    }

    if (subcategory_id && subcategory_id != '') {
      try {

        const response = await ProductService.index({ category_id: subcategory_id, status: 'ACTIVE' }, axiosPrivate);
        setProductList(response);
      } catch (error) {

      }
    }


    // Open the modal after resetting the form
    setIsOpen(true);
    switch (type) {
      case 'product':
        editType === 'product' ? setFormData({ ...formData, ...data, parent_category_id: category_id, type }) : setFormData({ ...formData, ...data.parent, parent_category_id: category_id, type });
        break;
      case 'subproduct':
        setFormData({ ...formData, ...data, parent_category_id: category_id, parent_id: product_id, type });
        break;
      case 'domain':
        setFormData({ ...formData, ...data.domain, type });
        break;
      case 'subcategory':
        editType === 'product' || editType === 'subproduct' ? setFormData({ ...formData, ...data.category, type }) : setFormData({ ...formData, ...data, type });
        break;
      case 'category':
        editType === 'subcategory' ? setFormData({ ...formData, ...data.parent, type }) : editType === 'category' ? setFormData({ ...formData, ...data, type }) : setFormData({ ...formData, ...data.category.parent, type });
        break;
    }
    console.log({ formData });
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    resetForm();
    setIsOpen(false);
  }

  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    if (viewType === "domain") {
      const newDomainFilters = {
        title: filterData.search.trim(),
        is_active: filterData.is_domain_active,
      };
      setDomainFilters(newDomainFilters);
      await fetchData("domain", newDomainFilters);
    } else if (viewType === "product") {
      const newProductFilters = {
        name: filterData.search.trim(),
        is_active: filterData.is_active,
        domain_id: filterData.domain,
        category_id: filterData.category,
        duration: filterData.duration,
      };
      setProductFilters(newProductFilters);
      await fetchData("product", newProductFilters);
    } else if (viewType === "category") {
      const newCategoryFilters = {
        title: filterData.search.trim(),
        is_active: filterData.is_category_active,
      };
      setCategoryFilters(newCategoryFilters);
      await fetchData("category", newCategoryFilters);
    }
  };

  const handleFilterChange = async (e) => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
    if (name === "search" && value === "") {
      if (viewType === "domain") {
        await fetchData("domain", filterData);
      } else if (viewType === "product") {
        delete filterData.name;
        await fetchData("product", filterData);
      } else if (viewType === "category") {
        await fetchData("category", filterData);
      }
    }

    if (name === "is_active" || name === "is_domain_active" || name === "is_category_active") {
      if (viewType === "product") {
        const newProductFilters = {
          ...productFilters,
          is_active: value,
          name: filterData.search.trim(),
          domain_id: filterData.domain,
          category_id: filterData.category,
          duration: filterData.duration,
        };
        setProductFilters(newProductFilters);
        await fetchData("product", newProductFilters);
      } else if (viewType === "domain") {
        const newDomainFilters = {
          ...domainFilters,
          is_active: value,
          title: filterData.search.trim(),
        };
        setDomainFilters(newDomainFilters);
        await fetchData("domain", newDomainFilters);
      } else if (viewType === "category") {
        const newCategoryFilters = {
          ...categoryFilters,
          is_active: value,
          title: filterData.search.trim(),
        };
        setCategoryFilters(newCategoryFilters);
        await fetchData("category", newCategoryFilters);
      }
    }
  };

  const handleNextPage = () => {
    if (currentPage < lastPage) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleLastPage = () => {
    if (currentPage !== lastPage) setCurrentPage(lastPage);
  };

  const handleFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };

  const handlePerPageChange = (e) => {
    setPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleSelectedSort = async (event) => {
    const selected = document.querySelector('.sort-header.active');
    let direction = true;
    if (selected?.id !== event.target.id) {
      selected?.classList.remove('active');
      event.target.classList.add('active');
      setSortDirection(true);
    } else {
      setSortDirection(!sortDirection);
      direction = !sortDirection;
    }

    const sortField = event.target.id === 'name' ? `${direction ? '' : '-'}name` : `${direction ? '' : '-'}${event.target.id}`;


    // Apply sorting to the appropriate view
    const updatedFilters = { ...filtersProduct, sort: sortField, page: 1 };

    // Update the filters and fetch data for the selected view type
    switch (viewType) {
      case "product":
        setProductFilters(updatedFilters);
        await fetchData("product", updatedFilters);
        break;
      case "domain":
        setDomainFilters(updatedFilters);
        await fetchData("domain", updatedFilters);
        break;
      case "category":
        setCategoryFilters(updatedFilters);
        await fetchData("category", updatedFilters);
        break;
      default:
        break;
    }
  };

  const handleEditMouseEnter = (index) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setHoveredRow(index);
  };

  const handleEditMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setHoveredRow(null);
    }, 250);
  };

  return (
    <>
      <MenuHeader />
      <div className="product-breadcumb">
        <div className="container">
          <div className="search">
            <select onChange={(e) => handleViewTypeChange(e.target.value)} value={viewType}>
              <option value="product">All Products</option>
              <option value="domain">All Domains</option>
              <option value="category">All Categories</option>
            </select>
          </div>
          <button onClick={() => openModal(null, null, null)} className="btn-add-product">
            Add
          </button>
        </div>
      </div>

      {viewType === "product" && (
        <div className="bloc-search no-background">
          <div className="container">
            <form onSubmit={handleFilterSubmit} className="search">
              <div className="text-search">
                <input
                  type="text"
                  name="search"
                  value={filterData.search}
                  onChange={handleFilterChange}
                  placeholder="Search by Name"
                  className="form-text"
                />
                <input type="submit" className="form-submit" value="Search" />
              </div>
              <select onChange={handleFilterChange} name="domain" value={filterData.domain}>
                <option value="">Domains</option>
                {domain.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.title}
                  </option>
                ))}
              </select>
              <select onChange={handleFilterChange} name="category" value={filterData.category}>
                <option value="">Categories</option>
                {category.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.title}
                  </option>
                ))}
              </select>
              <select onChange={handleFilterChange} name="duration" value={filterData.duration}>
                <option value="">Duration</option>
                <option value="30">30 min</option>
                <option value="60">60 min</option>
                <option value="90">90 min</option>
              </select>
              <select onChange={handleFilterChange} name="is_active" value={filterData.is_active}>
                <option value="">Status</option>
                <option value={true}>Active</option>
                <option value={false}>Disabled</option>
              </select>
            </form>
          </div>
        </div>
      )}

      {viewType === "domain" && (
        <div className="bloc-search no-background">
          <div className="container">
            <div className="bloc-search no-background">
              <div className="container">
                <form onSubmit={handleFilterSubmit} className="search">
                  <div className="text-search">
                    <input
                      type="text"
                      name="search"
                      value={filterData.search}
                      onChange={handleFilterChange}
                      placeholder="Search by Title"
                      className="form-text"
                    />
                    <input type="submit" className="form-submit" value="Search" />
                  </div>

                  <select onChange={handleFilterChange} name="is_domain_active" value={filterData.is_domain_active}>
                    <option value="">Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}


      {viewType === "category" && (
        <div className="bloc-search no-background">
          <div className="container">
            <div className="bloc-search no-background">
              <div className="container">
                <form onSubmit={handleFilterSubmit} className="search">
                  <div className="text-search">
                    <input
                      type="text"
                      name="search"
                      value={filterData.search}
                      onChange={handleFilterChange}
                      placeholder="Search by Title"
                      className="form-text"
                    />
                    <input type="submit" className="form-submit" value="Search" />
                  </div>

                  <select onChange={handleFilterChange} name="is_category_active" value={filterData.is_category_active}>
                    <option value="">Status</option>
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="tableau-list">
        <div className="container">
          {viewType === "product" && (
            <table>
              <thead className="no-background">
                <tr>
                  <th id="domain_id" onClick={handleSelectedSort} className="sort-header">Domain</th>
                  <th>Image</th>
                  <th id="category_id" onClick={handleSelectedSort} className="sort-header">Category</th>
                  <th id="subcategory_id" onClick={handleSelectedSort} className="sort-header">Subcategory</th>
                  <th id="name" onClick={handleSelectedSort} className="sort-header">Product</th>
                  <th id="subproduct" onClick={handleSelectedSort} className="sort-header">Subproduct</th>
                  <th id="duration" onClick={handleSelectedSort} className="sort-header">Duration (min)</th>
                  <th id="price" onClick={handleSelectedSort} className="sort-header">Price (CHF)</th>
                  <th id="is_hotel_product">Is Hotel Product</th>
                  <th id="hotel_name">Hotel Name</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header">Status</th>
                  <th>Edit/Disable</th>
                </tr>
              </thead>
              <tbody>
                {products.map((row, index) => (
                  <tr
                    key={index}
                    className={
                      !row?.is_active
                        ? "disabled-item"
                        : ""
                    }
                  >
                    <td className="domain-txt-color">{row?.domain?.title ? row?.domain?.title : '-'}</td>
                    <td>
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Product Logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{row?.category?.parent?.title ? row?.category?.parent?.title : '-'}</td>
                    <td>{row.category?.title ? row.category?.title : '-'}</td>
                    <td>{row.name}</td>
                    <td>
                      {row?.sub_products?.map((product, index) => `${product.name}${index === row.sub_products?.length - 1 ? '' : ', '}`)}
                    </td>
                    <td>{row.duration}</td>
                    <td>{row.price}</td>
                    <td>
                      {row.hotel_id ? "Yes" : "No"}
                    </td>
                    <td>
                      {row?.hotel?.name ? row.hotel.name : '-'}
                    </td>
                    <td className={row.is_active ? "active" : "disabled"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td>
                      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
                        <AntSwitch
                          defaultChecked={row.is_active}
                          onChange={handleSwitchChange(index, row)}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <div
                          className="edit"
                          onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                          style={{ position: "relative", marginLeft: "25px" }}
                          onMouseLeave={handleEditMouseLeave}
                          onMouseEnter={handleEditMouseEnter}
                        >
                          &#8942;
                          {hoveredRow === index && (
                            <div className="dropdown-menu">
                              <ul>
                                {row.parent_id ? (
                                  <>
                                    <li onClick={() => openModal(row, "domain", "subproduct")}>Edit Domain</li>
                                    <li onClick={() => openModal(row, "product", "subproduct")}>Edit Parent Product</li>
                                    <li onClick={() => openModal(row, "subcategory", "subproduct")}>Edit Category</li>
                                    <li onClick={() => openModal(row, "category", "subproduct")}>
                                      Edit Parent Category
                                    </li>
                                  </>
                                ) : (
                                  <>
                                    <li onClick={() => openModal(row, "domain", "product")}>Edit Domain</li>
                                    <li onClick={() => openModal(row, "product", "product")}>Edit Product</li>
                                    <li onClick={() => openModal(row, "subcategory", "product")}>Edit Category</li>
                                    <li onClick={() => openModal(row, "category", "product")}>
                                      Edit Parent Category
                                    </li>
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot >
                <tr>
                  <td colSpan={10}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>

            </table>
          )}

          {viewType === "domain" && (
            <table>
              <thead className="no-background">
                <tr>
                  <th id="title" onClick={handleSelectedSort} className="sort-header">Domain Title</th>
                  <th id="description" onClick={handleSelectedSort} className="sort-header">Description</th>
                  <th>Image</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header">Status</th>
                  <th>Edit/Disable</th>
                </tr>
              </thead>
              <tbody>
                {domainsData.map((row, index) => (
                  <tr key={index} className={!row.is_active ? "disabled-item" : ""}>
                    <td className="domain-txt-color">{row.title}</td>
                    <td>{row.description}</td>
                    <td>
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Domain Logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td className={row.is_active ? "active" : "disabled"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td>
                      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
                        <AntSwitch
                          defaultChecked={row.is_active}
                          onChange={async () => {
                            await DomainService.update(row.id, { is_active: !row.is_active }, axiosPrivate);
                            fetchData("domain");
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <div
                          className="edit"
                          onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                          style={{ position: "relative", marginLeft: "25px" }}
                          onMouseLeave={handleEditMouseLeave}
                          onMouseEnter={handleEditMouseEnter}
                        >
                          &#8942;
                          {hoveredRow === index && (
                            <div className="dropdown-menu">
                              <ul>
                                <li onClick={() => openModal({ domain: row }, "domain", "domain")}>Edit Domain</li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot >
                <tr>
                  <td colSpan={5}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}

          {viewType === "category" && (
            <table>
              <thead className="no-background">
                <tr>
                  <th id="title" onClick={handleSelectedSort} className="sort-header">Category Title</th>
                  <th>Image</th>
                  <th id="parent.title" onClick={handleSelectedSort} className="sort-header">Parent ID</th>
                  <th id="is_active" onClick={handleSelectedSort} className="sort-header">Status</th>
                  <th>Edit/Disable</th>
                </tr>
              </thead>
              <tbody>
                {categoriesData.map((row, index) => (
                  <tr key={index} className={!row.is_active ? "disabled-item" : ""}>
                    <td>{row.title}</td>
                    <td>
                      <img
                        src={`${row.media[0]?.original_url}`}
                        alt="Category Logo"
                        style={{ width: "50px", height: "50px" }}
                      />
                    </td>
                    <td>{row.parent ? row.parent.title : "No parent"}</td>
                    <td className={row.is_active ? "active" : "disabled"}>
                      {row.is_active ? "Active" : "Disabled"}
                    </td>
                    <td>
                      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
                        <AntSwitch
                          defaultChecked={row.is_active}
                          onChange={async () => {
                            await CategoryService.update(
                              row.id,
                              { is_active: !row.is_active },
                              axiosPrivate
                            );
                            fetchData("category");
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <div
                          className="edit"
                          onClick={() => setHoveredRow((prev) => (prev === index ? null : index))}
                          style={{ position: "relative", marginLeft: "25px" }}
                          onMouseLeave={handleEditMouseLeave}
                          onMouseEnter={handleEditMouseEnter}
                        >
                          &#8942;
                          {hoveredRow === index && (
                            <div className="dropdown-menu">
                              <ul>
                                {row.parent ? (
                                  <>
                                    <li onClick={() => openModal(row, "category", "subcategory")}>Edit Parent Category</li>
                                    <li onClick={() => openModal(row, "subcategory", "subcategory")}>Edit Category</li>
                                    <li onClick={() => openModal(row, "domain", "subcategory")}>Edit Domain</li>
                                  </>
                                ) : (
                                  <>
                                    <li onClick={() => openModal(row, "category", "category")}>Edit Category</li>
                                    <li onClick={() => openModal(row, "domain", "category")}>Edit Domain</li>
                                  </>
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot >
                <tr>
                  <td colSpan={5}>
                    <div className="paginator">
                      <select value={perPage} onChange={handlePerPageChange} className="per-page">
                        <option value={1}>1</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={250}>250</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                      </select>
                      <div className="total">Total Records: {total}</div>
                      <div className="pagination">
                        <button type="button" disabled={currentPage === 1} onClick={handleFirstPage}>{'<<'}</button>
                        <button type="button" disabled={currentPage === 1} onClick={handlePrevPage}>{'<'}</button>
                        <span>{currentPage} / {lastPage}</span>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleNextPage}>{'>'}</button>
                        <button type="button" disabled={currentPage === lastPage} onClick={handleLastPage}>{'>>'}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="close-button" onClick={closeModal}>
          <span className="close-icon">✖</span>
        </button>

        <h2>{formData.id ? 'Update' : 'Add'} a {asTitle(formData.type)}</h2>
        <p>If you need more info, please check out</p>
        <div className="form-inscription">
          <form onSubmit={handleSubmit}>
            {(!formData.id) && (
              <div className="form-text">
                <select value={formData.type} name="type" onChange={handleChange}>
                  <option value="">Add ...</option>
                  <option value="domain">Add New Domain</option>
                  <option value="category">Add New Category</option>
                  <option value="subcategory">Add New Subcategory</option>
                  <option value="product">Add New Product</option>
                  <option value="subproduct">Add New Subproduct</option>
                </select>
              </div>
            )}
            {((formData.type === "category" || formData.type === "subcategory" || formData.type === "product" || formData.type === "subproduct")) && (
              <>
                <div className="form-text">
                  <label>Domain*</label>
                  <select required name="domain_id" onChange={handleChange} value={formData.domain_id}>
                    <option>Select a domain</option>
                    {domain.length > 0 ? (
                      domain.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No domains available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "product" || formData.type === "subproduct") && (
              <>
                <div className="form-text">
                  <label>Category*</label>
                  <select value={formData.parent_category_id} required name="parent_category_id" onChange={handleChange}>
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "subcategory") && (
              <>
                <div className="form-text">
                  <label>Category*</label>
                  <select required name="parent_id" onChange={handleChange} value={formData.parent_id}>
                    <option value="">Select a category</option>
                    {categories.length > 0 ? (
                      categories.map((row) => (
                        <option key={row.id} value={row.id}>{row.title}</option>
                      ))
                    ) : (
                      <option value="" disabled>No categories available</option>
                    )}
                  </select>
                </div>
              </>
            )}

            {(formData.type === "product" || formData.type === "subproduct") && <>
              <div className="form-text">
                <label>Subcategory*</label>
                <select value={formData.category_id} required name="category_id" onChange={handleChange}>
                  <option value="">Select a Subcategory</option>
                  {subCategories.length > 0 ? (
                    subCategories.map((row) => (
                      <option key={row.id} value={row.id}>{row.title}</option>
                    ))
                  ) : (
                    <option value="" disabled>No Subcategories available</option>
                  )}
                </select>
              </div>

            </>
            }

            {formData.type === "subproduct" && <>
              <div className="form-text">
                <label>Product*</label>
                <select value={formData.parent_id} required name="parent_id" onChange={handleChange}>
                  <option value="">Select a product</option>
                  {productList.length > 0 ? (
                    productList.map((row) => (
                      <option key={row.id} value={row.id}>{row.name}</option>
                    ))
                  ) : (
                    <option value="" disabled>No products available</option>
                  )}
                </select>
              </div>
            </>
            }

            <div className="form-text">
              <label>
                {(formData.type === "product" || formData.type === "subproduct")
                  ? "Product Name"
                  : "Title"}
                *
              </label>
              <input value={formData.type === "product" || formData.type === "subproduct" ? formData.name : formData.title} required type="text" name={formData.type === "product" || formData.type === "subproduct" ? "name" : "title"} onChange={handleChange} />

            </div>
            <div className="form-text topMargArea">
              <label>Description*</label>
              <textarea
                required
                className="formTextArea"
                type="textarea"
                value={formData.description}
                name="description"
                onChange={handleChange}
              />
            </div>
            {(formData.type === "product" || formData.type === "subproduct") && (
              <>
                <div className="form-text">
                  <label>Duration *</label>
                  <input
                    required={formData.type === "product" || formData.type === "subproduct"}
                    type="number"
                    id="duration"
                    name="duration"
                    value={formData.duration}
                    onChange={handleChange}
                    min="0"
                    max="240"
                    placeholder="Enter duration (in minutes)"
                    step="1"  // Ensure the input increments by 1 minute
                  />
                </div>

                <div className="form-text">
                  <label>Price*</label>
                  <input
                    required={formData.type === "product" || formData.type === "subproduct"}
                    type="number"
                    name="price"
                    min="0"
                    max="9999"
                    value={formData.price}
                    onChange={handleChange}
                    step="0.01"
                    placeholder="Enter price (CHF)"
                  />
                </div>
              </>
            )}

            <label className="fileLabel" htmlFor="file">
              Image to upload*
            </label>
            <div className="fileField">
              <label htmlFor="file">
                <img className="imgFile" src={imageFile || ""} alt="File upload" />
                {fileName ? (
                  <div>Uploaded File: {fileName}</div>
                ) : (
                  <>
                    <div className="selectFileTo">Select file to upload</div>
                    <div className="orDragAnd">
                      or drag and drop, copy-paste files
                    </div>
                  </>
                )}
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
            </div>

            <div className="capability-input">
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>Status</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Disabled</Typography>
                  <Switch
                    checked={formData.is_active ? true : false}
                    value={formData.is_active ? true : false}
                    onChange={handleFormSwitchChange}
                    inputProps={{ "aria-label": "status" }}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </Stack>
            </div>

            <div className="actions">
              <button type="submit" onClick={closeModal} className="btn-submit">
                Cancel
              </button>
              <button type="submit" className="btn-submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default Product;
