export const asTitle = (input) => {
    return input
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ').split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export const addressFormat = (input) => {
    return `${input?.street_number ?? ''}${input?.street_number ? ', ' : ''}${input?.street ?? ''}${input?.street ? ', ' : ''}${input?.city ?? ''}${input?.city ? ', ' : ''}${input?.zip_code ?? ''}, ${input?.state_code ?? ''}, ${input?.country_code ?? ''}`
}

export const castDate = (input) => {
    const date = new Date(input);

    const formatter = new Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'Europe/Paris' // Optional: specify timezone
    });

    return formatter.format(date);
}

export const formatTimeTo24Hour = (timeStr) => {
    const [time, modifier] = timeStr.split(' ');
    let [hour, minute] = time.split(':').map(Number);

    if (modifier === 'PM' && hour < 12) hour += 12;
    if (modifier === 'AM' && hour === 12) hour = 0;

    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
};