import { axiosPrivate2 } from "../api/axios";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import useAuth from "./useAuth";
import { setupAuthorizationInterceptor } from "../interceptors/authorization.interceptor";
import { setupEncryptorDecryptorInterceptor } from "../interceptors/encryptor.interceptor";
import { setupCsrfInterceptor } from "../interceptors/csrf.interceptor";
import { setupLoadingInterceptor } from "../interceptors/loading.interceptor";
import { setupNotificationInterceptor } from "../interceptors/notification.interceptor";
import { useLoading } from '../context/LoadingProvider';
import { useNotifications } from '../context/NotificationProvider';

const useAxiosPrivate2 = () => {
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const loader = useLoading();
    const notify = useNotifications();

    useEffect(() => {
        const authorizationInterceptor = setupAuthorizationInterceptor(axiosPrivate2, refresh, auth);
        const encryptorDecryptorInterceptor = setupEncryptorDecryptorInterceptor(axiosPrivate2);
        const csrfInterceptor = setupCsrfInterceptor(axiosPrivate2);
        const loadingInterceptor = setupLoadingInterceptor(axiosPrivate2, loader);
        const notificationInterceptor = setupNotificationInterceptor(axiosPrivate2, notify);
        return () => {
            // Cleanup interceptors
            authorizationInterceptor();
            encryptorDecryptorInterceptor();
            csrfInterceptor();
            loadingInterceptor();
            notificationInterceptor();
        };
    }, [auth, refresh, loader, notify]);

    return axiosPrivate2;
};

export default useAxiosPrivate2;
