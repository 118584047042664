import { encrypt, decrypt } from '../shared/utils/crypto-utils';

export const setupEncryptorDecryptorInterceptor = (axiosInstance) => {
    const requestIntercept = axiosInstance.interceptors.request.use(
        (config) => {
            if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
                console.log(config.data)
                const { encrypted, iv } = encrypt(JSON.stringify(config.data));

                config.data = {
                    data: encrypted,
                    iv,
                };
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
        (response) => {
            return decryptResponse(response);
        },
        (error) => {
            error.response = decryptResponse(error.response);
            return Promise.reject(error);
        }
    );
    const decryptResponse = (response) => { 
        if (response?.data?.data && response?.data?.iv) {
            try {
                const decrypted = decrypt(response.data.data, response.data.iv);
                response.data = JSON.parse(decrypted);
            } catch (error) {
                console.error('Decryption failed', error);
            }
        }
        return response;
    }
    // Cleanup function to remove interceptors
    return () => {
        axiosInstance.interceptors.request.eject(requestIntercept);
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};

export const setupDecryptorInterceptor = (axiosInstance) => {
    const responseIntercept = axiosInstance.interceptors.response.use(
        (response) => {
            return decryptResponse(response);
        },
        (error) => {
            error.response = decryptResponse(error.response);
            return Promise.reject(error);
        }
    );
    const decryptResponse = (response) => { 
        if (response?.data?.data && response?.data?.iv) {
            try {
                const decrypted = decrypt(response.data.data, response.data.iv);
                response.data = JSON.parse(decrypted);
            } catch (error) {
                console.error('Decryption failed', error);
            }
        }
        return response;
    }
    // Cleanup function to remove interceptors
    return () => {
        axiosInstance.interceptors.response.eject(responseIntercept);
    };
};
